import _Vue from "vue";
import * as msal from "@azure/msal-browser";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-common";
import User from "../Models/User";
import { AppSettings } from "../assets/constants";

export class MsalHelper extends msal.PublicClientApplication {
  constructor(options: msal.Configuration | null = null) {
    if (options == null) {
      const appSettings = new AppSettings();
      options = {
        auth: {
          clientId: appSettings.Clientid,
          authority: `https://login.microsoftonline.com/${appSettings.TenantId}/`,
          redirectUri: appSettings.RootUrl,
        },
        cache: {
          cacheLocation: "localStorage",
        },
      };
    }

    super(options);
  }

  public callMSGraph(endpoint: any, accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(endpoint, options)
      .then((response) => response)
      .catch((error) => console.log(error));
  }

  login(): Promise<msal.AuthenticationResult> {
    const scopes = ["api://ec433199-f4fa-483a-be52-ad966d6842e6/bookkeeping.rw"];
    return this.loginPopup({ scopes });
  }

  async getSilentToken(account: any, scopes = ["User.Read"]): Promise<AuthenticationResult | void | undefined> {
    const silentRequest = { account, scopes };
    try {
      return await this.acquireTokenSilent(silentRequest);
    } catch (error) {
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        this.login;
        //    return this.acquireTokenRedirect(silentRequest);
      }
    }
  }

  async SilentLogon(): Promise<AuthenticationResult | null> {
    const accounts = this.getAllAccounts();
    if (accounts.length > 0) {
      const response = await this.getSilentToken(accounts[0], [
        "api://ec433199-f4fa-483a-be52-ad966d6842e6/bookkeeping.rw",
      ]);
      if (response !== undefined && response !== null) {
        return response;
      }
    }

    return null;
  }

  IsInRole(user: User, role: string): boolean {
    if (user.roles == null) return false;
    if (user.roles.findIndex((e) => e == "Admin") >= 0) return true;
    return user.roles.findIndex((e) => e == role) >= 0;
  }
}

export function MsalPluginFactory(Vue: typeof _Vue): void {
  const appSettings = new AppSettings();
  Vue.prototype.$msal = new MsalHelper({
    auth: {
      clientId: appSettings.Clientid,
      authority: `https://login.microsoftonline.com/${appSettings.TenantId}/`,
      redirectUri: appSettings.RootUrl,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  });
}
